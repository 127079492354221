import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';

import Folder from '../folder/folder';
import { appActions } from '../../redux/app/reducer';
import styles from './sideBar.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getFile, getFilesList, getIsDirty, getLanguages, getSelectedGame } from '../../redux/app/selectors';
import { findElementByPath } from '../../utils/findElementByPath';
import { modalsActions } from '../../redux/modals/reducer';
import { modalsTypes } from '../../redux/modals/modalsTypes';
import { createConfirmModal } from '../../utils/createConfirmModal';

export default React.memo(forwardRef(function SideBar({
                                                        selectedTranslations,
                                                        searchTerm,
                                                        selectedFolders,
                                                        saveCallback
                                                      }, ref) {
  const filesList = useSelector(getFilesList);
  const languages = useSelector(getLanguages);
  const json = useSelector(getFile);
  const isFileDirty = useSelector(getIsDirty);
  const selectedGame = useSelector(getSelectedGame);
  const [multiSelect, setMultiSelect] = useState(false);
  const [shiftSelect, setShiftSelect] = useState(false);
  const dispatch = useDispatch();

  const dontSave = useCallback((folder) => {
    dispatch(appActions.resetState());
    dispatch(appActions.setSelectedGame(folder));
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    dontSave(folder) {
      dontSave(folder)
    },
  }));

  const onRootFolderClick = useCallback((folder) => {
    if (selectedGame && (selectedGame !== folder) && isFileDirty) {
      return dispatch(modalsActions.showModal(createConfirmModal(
        () => dontSave(folder), () => saveCallback(folder), `Don't save`, 'Save',
        `Are you sure you wan't leave this file without saving ?`,
        'Cancel', null
      )));
    }

    if (selectedGame && (selectedGame !== folder) && !isFileDirty) {
      dispatch(appActions.resetState());
      dispatch(appActions.setSelectedGame(folder));
      return ;
    }

    if (selectedFolders.includes(folder)) return;

    if (!selectedGame) {
      return dispatch(appActions.setSelectedGame(folder));
    }

    dispatch(appActions.setSelectedTranslations([]));
    dispatch(appActions.setSelectedFolders([folder]));
  }, [isFileDirty, dontSave, dispatch, saveCallback, selectedFolders, selectedGame]);

  const onExportSelected = () => {
    if (!selectedTranslations.length && !selectedFolders.length) return;

    const configs = [];
    selectedTranslations.forEach(path => configs.push({ path, file: findElementByPath(json, path) }));
    dispatch(appActions.setMergeFiles(configs));
    dispatch(modalsActions.showModal({ type: modalsTypes.EXPORT_FILES_TO }));
  };

  const enableMultiSelect = useCallback(e => {
    if (e.keyCode === 91) {
      setMultiSelect(true);
      console.log('multi select enabled')
    }
  }, []);

  const disableMultiSelect = useCallback(e => {
    if (e.keyCode === 91) {
      setMultiSelect(false);
      console.log('multi select disabled')
    }
  }, []);

  const enableShiftSelect = useCallback(e => {
    if (e.keyCode === 16) {
      setShiftSelect(true);
      console.log('GOD mode enabled')
    }
  }, []);

  const disableShiftSelect = useCallback(e => {
    if (e.keyCode === 16) {
      setShiftSelect(false);
      console.log('GOD mode disabled')
    }
  }, []);

  const saveShortCut = useCallback(e => {
    if (multiSelect && e.keyCode === 83) {
      e.preventDefault();
      dispatch(modalsActions.showModal(createConfirmModal(
        null, saveCallback, `Cancel`, 'Save',
        `Save current translations ?`
      )));
    }
  }, [multiSelect, saveCallback, dispatch]);

  useEffect(() => {
    document.addEventListener('keydown', enableMultiSelect);
    document.addEventListener('keyup', disableMultiSelect);
    document.addEventListener('keydown', enableShiftSelect);
    document.addEventListener('keyup', disableShiftSelect);
    document.addEventListener('keydown', saveShortCut);

    return () => {
      document.removeEventListener('keydown', enableMultiSelect);
      document.removeEventListener('keyup', disableMultiSelect);
      document.removeEventListener('keydown', enableShiftSelect);
      document.removeEventListener('keyup', disableShiftSelect);
      document.removeEventListener('keydown', saveShortCut);
    }
  }, [enableMultiSelect, disableMultiSelect, saveShortCut, enableShiftSelect, disableShiftSelect]);

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <span className={styles.head_title}>
          Translations
        </span>
        <span onClick={onExportSelected}
              className={`button ${styles.save_button} ${!selectedTranslations.length && !selectedFolders.length ? 'button_disabled' : ''}`}>
          Copy selected
        </span>
      </div>

      <div className={styles.folders_box} id={'sidebar_file_system'}>

        {filesList.map(el =>
          <span key={el}>
            <Folder isItFolder
                    title={el}
                    path={''}
                    obj={(el === selectedGame) ? json : {}}
                    languages={languages}
                    searchTerm={searchTerm}
                    selectedFolders={selectedFolders}
                    selectedTranslations={selectedTranslations}
                    onRootFolderClick={onRootFolderClick}
                    root={el}
                    multiSelect={multiSelect}
                    shiftSelect={shiftSelect}
            />
          </span>)}
      </div>
    </div>
  );
}))
