export const changeKeysToLanguage = (obj, language) => {
  if (!language) return;

  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      changeKeysToLanguage(obj[key], language);
      continue;
    }
    obj[key] = {
      [language]: obj[key]
    };
  }
};

const mergeObjects = (obj1, obj2) => {
  if (typeof obj1 === 'object' || typeof obj2 === 'object') {
    for (let key in obj2) {
      if (obj1.hasOwnProperty(key) && typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        mergeObjects(obj1[key], obj2[key]);
        continue;
      }
      if (obj2[key] && obj1.hasOwnProperty(key) && obj1[key] === '') {
        obj1[key] = obj2[key];
        continue;
      }
      if (obj2[key] && obj1.hasOwnProperty(key)) {
        obj1[key] = obj2[key];
        continue;
      }
      if (obj1.hasOwnProperty(key)) {
        mergeObjects(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
};

export const importJson = (file, path, json) => {

  let pathArray = path.split('.').filter(el => el !== '');
  let currentFolder = file;

  if (!pathArray.length) {
    mergeObjects(file, json);
    return file;
  }

  for (let i = 0; i < pathArray.length; i++) {
    if (!currentFolder.hasOwnProperty(pathArray[i])) {
      currentFolder[pathArray[i]] = {};
    }

    if (pathArray.length - 1 === i) {
      mergeObjects(currentFolder[pathArray[i]], json);
      break;
    }

    currentFolder = currentFolder[pathArray[i]];
  }

  return file;
};
