export const findElementByPath = (obj, path) => {
  let pathArray = path.split('.');
  pathArray = pathArray.filter(el => el !== '');
  let result = obj;
  for (let i = 0; i < pathArray.length; i++) {
    if (!result.hasOwnProperty(pathArray[i])) {
      return result = null;
    }
    result = result[pathArray[i]];
  }

  return result;
};
