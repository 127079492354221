export const calculateFulfilled = (obj) => {
  let keysCount = Object.keys(obj).length;

  let emptyKeysCount = 0;
  Object.values(obj).forEach(el => {
    if (el === '') {
      emptyKeysCount += 1;
    }
  });

  if (obj.hasOwnProperty('tip')) {
    keysCount -= 1;
    if (obj.tip === '') {
      emptyKeysCount -= 1
    }
  }

  const result = 100 - emptyKeysCount / keysCount * 100;
  return Number(result.toFixed(0));
};
