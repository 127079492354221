import React, { useEffect, useRef, useState } from 'react';
import styles from './translationBlock.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../redux/app/reducer';
import { getLanguages } from '../../redux/app/selectors';
import { modalsActions } from '../../redux/modals/reducer';
import { modalsTypes } from '../../redux/modals/modalsTypes';

export default function TranslationBlock({ translations, path }) {
  const dispatch = useDispatch();
  const languages = useSelector(getLanguages);
  const [inputValues, setInputValues] = useState(translations);
  const containerRef = useRef();

  useEffect(() => {
    setInputValues(translations);
  }, [translations]);

  const onChangeTranslation = (e) => {
    setInputValues({ ...inputValues, [e.currentTarget.id]: e.currentTarget.value });
  };

  const onEditClick = () => {
    dispatch(modalsActions.showModal({
      type: modalsTypes.EDIT_TRANSLATION,
      data: { path, tip: translations.tip || '' }
    }));
  };

  const save = () => {
    dispatch(appActions.changeTranslation({ path, translations: inputValues }));
  };

  return !translations ? null : (
    <div ref={containerRef} className={styles.container}>
      <span className={styles.title}>
        {path}
        <span onClick={onEditClick}>
          Edit
        </span>
      </span>

      {translations.tip &&
      <span className={styles.tip}>
        {translations.tip}
      </span>}

      {
        Object.keys(translations).filter(el => languages.list.includes(el)).map(key =>
          key === 'tip' ? null :
            <div key={key + path} className={styles.translation_line}>
              <span>{key}</span>
              <div>
                <textarea value={inputValues[key]} onBlur={save} onChange={onChangeTranslation} id={key} />
              </div>
            </div>
        )}
    </div>
  );
}
