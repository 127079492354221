import { findElementByPath } from './findElementByPath';

export const findOneLevelSelectedFolders = (obj, currentPath, selectedFolders) => {
  const commonPath = currentPath.slice(0, currentPath.lastIndexOf('.'));
  const currentObj = findElementByPath(obj, commonPath);
  const keys = Object.keys(currentObj);
  const folders = [];
  keys.forEach(key => folders.push(commonPath + '.' + key));

  const lastSelected = selectedFolders[selectedFolders.length - 1];
  if (!folders.includes(lastSelected)) return [];

  const lastSelectedIndex = folders.indexOf(lastSelected);
  const currentIndex = folders.indexOf(currentPath);
  const result = lastSelectedIndex < currentIndex ?
    folders.slice(lastSelectedIndex + 1, currentIndex + 1) :
    folders.slice(currentIndex, lastSelectedIndex);

  return result.filter(el => !selectedFolders.includes(el));
};
