export const isFolderContainSearchTerm = (obj, objectKey, term, path) => {
  term = term.toUpperCase();

  if (objectKey && objectKey.toUpperCase().includes(term)) {
    return true;
  }
  if (path && path.slice(path.lastIndexOf('.') + 1).toUpperCase().includes(term)) {
    return true;
  }

  let contain;

  const findInPath = (obj, term) => {
    for (let key in obj) {
      if (key.toUpperCase().includes(term)) {
        return contain = true;
      }
      if (obj[key] && typeof obj[key] === 'object') {
        const result = findInPath(obj[key], term);
        if (result) {
          return contain = true;
        }
        continue;
      }
      if (obj[key].toUpperCase().includes(term) || key.toUpperCase().includes(term)) {
        return contain = true;
      }
      contain = false;
    }
  };

  findInPath(obj, term);
  return contain;
};

