import { modalsTypes } from '../redux/modals/modalsTypes';

export const createConfirmModal = (onCancel, onSubmit, cancelText, submitText,
                                   message, thirdButtonText, onThirdButtonClick) => {

  return {
    type: modalsTypes.CONFIRM,
    data: {
      onCancel,
      onSubmit,
      cancelText,
      submitText,
      message,
      thirdButtonText,
      onThirdButtonClick
    }
  }
};
