export const isFolder = (obj) => {
  let result = false;
  if (typeof obj === 'object') {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        result = true;
      }
    }
  }
  return result;
};
