import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg';

import styles from './languageModal.module.css';
import { useDispatch } from 'react-redux';
import { appActions } from '../../../redux/app/reducer';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { modalsActions } from '../../../redux/modals/reducer';
import { createConfirmModal } from '../../../utils/createConfirmModal';

export default React.memo(function EditModal({ selected, languages, setShowEditWindow, setSelected }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(selected);
  // const [isDefaultChecked, setIsDefaultChecked] = useState(selected);

  useEffect(() => {
    setInputValue(selected);
  }, [selected]);

  // useEffect(() => {
  //   if (!selected || !languages.default) return;
  //   setIsDefaultChecked(selected === languages.default);
  // }, [languages.default, selected]);
  //
  // const onChangeDefault = (e) => {
  //   setIsDefaultChecked(e.currentTarget.checked);
  // };

  const onInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const onCancel = useCallback(() => {
    setSelected('');
    setShowEditWindow(false);
  }, [setShowEditWindow, setSelected]);

  const onDeleteLanguage = useCallback(() => {
    dispatch(appActions.removeLanguage(selected));
    onCancel();
  }, [dispatch, selected, onCancel]);

  const onDeleteClick = () => {
    if (selected === languages.default) {
      return dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: `You can't delete default language` }));
    }

    return dispatch(modalsActions.showModal(
      createConfirmModal(null, onDeleteLanguage, 'Cancel', 'Delete', 'Are you sure you want delete this language ?')
    ));
  };

  const onSubmit = () => {
    // if (languages.default === selected && !isDefaultChecked) {
    //   return dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: 'Need to set default language' }));
    // }
    //
    // if (selected === inputValue && (selected === languages.default) === isDefaultChecked) {
    //   onCancel();
    // }

    if (selected !== inputValue && languages.list.includes(inputValue)) {
      return dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: 'This language already exist!' }));
    }

    // if (selected === inputValue && (selected === languages.default) !== isDefaultChecked) {
    //   // dispatch(appActions.setDefaultLanguage(inputValue));
    //   return onCancel();
    // }
    if (selected !== inputValue) {
      dispatch(appActions.editLanguage({ langName: selected, newLangName: inputValue }));
    }
    // if (isDefaultChecked) {
    //   dispatch(appActions.setDefaultLanguage(inputValue));
    // }
    onCancel();
  };

  return <div className={'modal'}>

      <span onClick={onDeleteClick} className={'modal_delete'}>
        Delete
        <DeleteIcon />
      </span>

      <span className={'modal_title'}>EDIT LANGUAGE</span>
      <div className={`${styles.container} ${styles.controls}`}>
        <input value={inputValue} onChange={onInputChange} />
        {/*<div className={styles.checkbox_box}>*/}
        {/*  <input checked={isDefaultChecked} onChange={onChangeDefault} type={'checkbox'} />*/}
        {/*  <label>Set as default language</label>*/}
        {/*</div>*/}
        <div className={styles.buttons_box}>
          <span onClick={onCancel} className={'button'}>Cancel</span>
          <span onClick={onSubmit} className={'button'}>Ok</span>
        </div>
      </div>
    </div>
})
