import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../languageModal/languageModal.module.css';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { modalsActions } from '../../../redux/modals/reducer';

export default React.memo(function ConfirmModal() {
  const dispatch = useDispatch();
  const modal = useSelector(getModalInfo(modalsTypes.CONFIRM));
  const data = modal.data || {};

  const onClick = (e) => {
    const callback = data[e.currentTarget.dataset.callback];
    dispatch(modalsActions.closeModal({ type: modalsTypes.CONFIRM }));
    callback && callback();
  };

  return !modal.visible ? null : (
    <div className={'modal_container modal_container_confirm'}>
      <div className={'modal confirm_modal'}>
        <span className={'modal_title'}>Confirm operation</span>
        <span className={'modal_body scroll'}>
        {data.message.__proto__ === Function.prototype ? data.message() : data.message}
      </span>
        <div className={styles.buttons_box}>
          {data.thirdButtonText &&
          <span onClick={onClick} className={'button'}
                data-callback={'onThirdButtonClick'}>{data.thirdButtonText}</span>
          }
          <span onClick={onClick} className={'button'} data-callback={'onCancel'}>{data.cancelText}</span>
          <span onClick={onClick} className={'button'} data-callback={'onSubmit'}>{data.submitText}</span>
        </div>
      </div>
    </div>
  );
})
