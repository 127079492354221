import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import TranslationBlock from '../translationBlock/translationBlock';
import { findElementByPath } from '../../utils/findElementByPath';
import styles from './main.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getFile } from '../../redux/app/selectors';
import { modalsActions } from '../../redux/modals/reducer';
import { modalsTypes } from '../../redux/modals/modalsTypes';

export default React.memo(function Main({ selectedTranslations }) {
  const dispatch = useDispatch();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: 1, accept: 'application/json' });
  const json = useSelector(getFile);
  const [showDropZone, setShowDropZone] = useState(false);

  useEffect(() => {
    if (!acceptedFiles.length) return;
    dispatch(modalsActions.showModal({ type: modalsTypes.IMPORT_JSON, data: acceptedFiles[0] }));
    acceptedFiles.shift();
  }, [acceptedFiles, dispatch]);

  const onDragStop = useCallback(e => {
    e.preventDefault();
    setShowDropZone(false);
  }, []);

  const onDragStart = useCallback(e => {
    setShowDropZone(true);
    document.addEventListener('mouseup', onDragStop, { once: true })
  }, [onDragStop]);

  useEffect(() => {
    document.addEventListener('dragenter', onDragStart, false);
    document.addEventListener('drop', onDragStop, false);

    return () => {
      document.removeEventListener('dragenter', onDragStart);
      document.removeEventListener('drop', onDragStop);
    }
  }, [onDragStart, onDragStop]);

  const selected = useMemo(() => selectedTranslations.map(path => ({
    translations: findElementByPath(json, path),
    path: path
  })), [selectedTranslations, json]);

  return (
    <div className={styles.container}>

      {showDropZone &&
      <div className={styles.dropzoneWrap}>
        <div {...getRootProps({ className: `dropzone ${styles.dropzone}` })}>
          <span />
          <input {...getInputProps()} />
        </div>
      </div>
      }

      <span className={styles.title}>
        {selectedTranslations.length ? 'Edit translations' : 'Select translation'}
      </span>
      <div className={styles.content}>
        {selected.map(el =>
          <TranslationBlock key={el.path} translations={el.translations} path={el.path} />
        )}
      </div>
    </div>
  );
})
