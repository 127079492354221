export const createByPath = (file, path) => {
  const pathArray = path.split('.');
  pathArray.filter(el => el !== '');

  let currentFile = file;

  for (let i = 0; i < pathArray.length; i++) {
    if (!currentFile.hasOwnProperty(pathArray[i])) {
      currentFile[pathArray[i]] = {};
    }
    currentFile = currentFile[pathArray[i]];
  }

  return currentFile;
};
