import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from './components/header/header';
import SideBar from './components/sideBar/sideBar';
import Main from './components/main/main';
import ErrorModal from './components/modals/errorModal/errorModal';
import LanguageModal from './components/modals/languageModal/languageModal';
import TranslationModal from './components/modals/translationModal/translationModal';
import NotificationModal from './components/modals/notificationModal/notificationModal';
import LoginModal from './components/modals/loginModal/loginModal';
import ConfirmModal from './components/modals/confirmModal/confirmModal';
import ExportFilesModal from './components/modals/exportFilesModal/exportFilesModal';
import ImportJsonModal from './components/modals/importJsonModal/importJsonModal';
import EditTranslationModal from './components/modals/editTranslationModal/editTranslationModal';
import JsonCompareModal from './components/modals/jsonCompareModal/jsonCompareModal';

import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFile, getFilesList, getIsDirty, getLanguages,
  getSelectedFolders,
  getSelectedGame,
  getSelectedTranslations
} from './redux/app/selectors';
import { managerRest } from './index';
import { findAllTranslationsInFolder } from './utils/findAllTranslationsInFolder';
import { appActions } from './redux/app/reducer';
import { getIsAuth } from './redux/user/selectors';
import { isFolderContainSearchTerm } from './utils/isFolderContainSearchTerm';
import { findElementByPath } from './utils/findElementByPath';
import { removeEmpty } from './utils/removeEmpty';
import { deepCopyObject } from './utils/deepCopyObject';
import { modalsActions } from './redux/modals/reducer';
import { modalsTypes } from './redux/modals/modalsTypes';
import { compareModes } from './components/modals/jsonCompareModal/compareModes';
import CreateCollectionModal from './components/modals/createCollectionModal/createCollectionModal';

export default React.memo(function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(getIsAuth);
  const isDirty = useSelector(getIsDirty);
  const selectedTranslations = useSelector(getSelectedTranslations);
  const selectedFolders = useSelector(getSelectedFolders);
  const selectedGame = useSelector(getSelectedGame);
  const filesList = useSelector(getFilesList);
  const file = useSelector(getFile);
  const languages = useSelector(getLanguages);
  const [searchTerm, setSearchTerm] = useState('');
  const sideBarRef = useRef();

console.warn(file);
  const askBeforeUnload = useCallback((e) => {
    if (!isDirty) return;
    e.preventDefault();
    e.returnValue = '';
  }, [isDirty]);

  useEffect(() => {
    window.addEventListener('beforeunload', askBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', askBeforeUnload);
    }
  }, [askBeforeUnload]);

  useEffect(() => {
    if (!isAuth) return;
    managerRest.getGamesList();
  }, [isAuth]);

  useEffect(() => {
    if (!selectedGame) return;
    managerRest.getGameTranslation(selectedGame, true);
  }, [selectedGame]);

  useEffect(() => {
    if (!Object.keys(file).length) return;
    if (selectedGame && selectedFolders.length && filesList.includes(selectedFolders[0]) && selectedFolders[0] !== selectedGame) return;
    let allSelectedTranslations = [];

    selectedFolders.forEach(selectedFolder => {
      const folder = selectedFolder === selectedGame ? '' : selectedFolder;
      allSelectedTranslations = [
        ...allSelectedTranslations,
        ...findAllTranslationsInFolder(file, folder, languages.list).filter(el => !allSelectedTranslations.includes(el))];
    });

    dispatch(appActions.setSelectedTranslations(allSelectedTranslations));
  }, [selectedFolders, selectedGame, languages, file, dispatch, filesList]);

  const filteredTranslations = useMemo(() => {
    if (!searchTerm) return selectedTranslations;

    return selectedTranslations.filter(el => {
      const pathArray = el.split('.');
      const translationName = pathArray[pathArray.length - 1];
      return isFolderContainSearchTerm(findElementByPath(file, el), translationName, searchTerm, el);
    });
  }, [searchTerm, selectedTranslations, file]);

  const save = useCallback(async (folder) => {
    const success = await managerRest.saveTranslation(selectedGame, removeEmpty(deepCopyObject(file), languages.list));
    if (success && folder) {
      sideBarRef.current.dontSave(folder);
    }
  }, [selectedGame, file, languages.list]);

  // const showJsonPreview = useCallback((folder) => {
  //   dispatch(modalsActions.showModal(createConfirmModal(
  //     null, () => save(folder), `Cancel`, 'Save',
  //     () => <ReactJsonViewer src={file} name={'Translations'} collapsed={1} style={{fontSize: '1rem', textAlign: 'left'}}/>
  //   )));
  // }, [file, save, dispatch]);

  const showCompareModal = useCallback(async (folder) => {
    const latestJson = await managerRest.getGameTranslation(selectedGame);

    dispatch(modalsActions.showModal({
      type: modalsTypes.JSON_COMPARE,
      data: {
        target: latestJson,
        import: file,
        mode: compareModes.SAVE,
        nextFolder: folder
      }
    }));
  }, [selectedGame, file, dispatch]);

  return (
    <div className="app">
      <Header searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedFolders={selectedFolders}
              selectedTranslations={selectedTranslations}
              saveCallback={showCompareModal}
      />
      <div className={'main'}>
        <SideBar selectedTranslations={filteredTranslations}
                 searchTerm={searchTerm}
                 selectedFolders={selectedFolders}
                 saveCallback={showCompareModal}
                 ref={sideBarRef}
        />
        <Main selectedTranslations={filteredTranslations} />
      </div>

      <ErrorModal />
      <ConfirmModal />
      <JsonCompareModal saveCallback={save}/>
      <LoginModal />
      <LanguageModal />
      <ImportJsonModal />
      <ExportFilesModal />
      <EditTranslationModal />
      <NotificationModal />
      <TranslationModal selectedFolders={selectedFolders} />
      <CreateCollectionModal />
    </div>
  );
});
