import { createStore, combineReducers } from 'redux';
import { appReducer } from './app/reducer';
import { modalsReducer } from './modals/reducer';
import { userReducer } from './user/reducer';

const root = combineReducers({
  app: appReducer,
  modals: modalsReducer,
  user: userReducer,
});

export const store = createStore(root);
