export const addLanguage = (file, lang) => {
  if (!lang) return file;

  for (let key in file) {
    if (typeof file[key] === 'object') {
      file[key] = addLanguage(file[key], lang);
      continue;
    }
    if (!file.hasOwnProperty(lang)) {
      file[lang] = '';
    }
  }

  return file;
};
