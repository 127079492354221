export const validateBeforeInput = (e, regexp) => {
  if (e.data !== null) {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    const nextValue = e.target.value.substring(0, selectionStart).concat(e.data, e.target.value.substring(selectionEnd));

    if (!regexp.test(nextValue)) {
      e.preventDefault();
      return;
    }

    return nextValue;
  }
}
