import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './editTranslationModal.module.css';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { modalsActions } from '../../../redux/modals/reducer';
import { appActions } from '../../../redux/app/reducer';
import { findElementByPath } from '../../../utils/findElementByPath';
import { getFile } from '../../../redux/app/selectors';

export default React.memo(function EditTranslationModal() {
  const modal = useSelector(getModalInfo(modalsTypes.EDIT_TRANSLATION));
  const file = useSelector(getFile);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    key: '',
    tip: '',
  });

  useEffect(() => {
    if (!modal.data) return;
    const pathArray = modal.data.path.split('.');
    setInputs({ key: pathArray[pathArray.length - 1], tip: modal.data.tip });
  }, [modal.data]);

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.currentTarget.id]: e.currentTarget.value });
  };

  const onCancel = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.EDIT_TRANSLATION }));
  };

  const onSubmit = () => {
    if (!inputs.key) {
      return dispatch(modalsActions.showModal({type: modalsTypes.ERROR, data: 'You should specify translation key'}));
    }

    const pathArray = modal.data.path.split('.');
    const path = pathArray.slice(0, pathArray.length - 1).join('.');
    const key = pathArray[pathArray.length - 1];

    if (inputs.key && inputs.key !== key && findElementByPath(file,path + '.' + inputs.key)) {
      return dispatch(modalsActions.showModal({type: modalsTypes.ERROR, data: 'This key already exist'}));
    }

    dispatch(appActions.editTranslation({ path: path, oldKey: key, new: inputs }));
    dispatch(modalsActions.closeModal({ type: modalsTypes.EDIT_TRANSLATION }));
  };

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal'}>
      <span className={'modal_title'}>
        Edit
      </span>

        <div className={'modal_body modal_form'}>
          <div className={styles.form_block}>
            <label>Translation key:</label>
            <input value={inputs.key} onChange={onInputChange} id={'key'} />
          </div>
          <div className={styles.form_block}>
            <label>Tip:</label>
            <input value={inputs.tip} onChange={onInputChange} id={'tip'} />
          </div>
        </div>

        <div className={styles.controls}>
          <span onClick={onCancel} className={'button'}>Cancel</span>
          <span onClick={onSubmit} className={'button'}>Edit</span>
        </div>
      </div>
    </div>
  );
})
