export const addTranslation = (file, path, languages) => {
  let pathArray = path.split('.').filter(el => el !== '');
  let currentFolder = file;
  for (let i = 0; i < pathArray.length; i++) {
    if (!currentFolder.hasOwnProperty(pathArray[i])) {
      currentFolder[pathArray[i]] = {};
    }
    currentFolder = currentFolder[pathArray[i]];
  }
  for (let i = 0; i < languages.length; i++) {
    currentFolder[languages[i]] = '';
  }

  return file;
};
