export const merge = (obj, configs) => {
  configs.forEach(el => {
    const pathArray = el.path.split('.');
    let currentFolder = obj;
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === '') continue;
      if (!currentFolder.hasOwnProperty(pathArray[i])) {
        currentFolder[pathArray[i]] = {};
      }
      currentFolder = currentFolder[pathArray[i]];
    }
    currentFolder = Object.assign(currentFolder, el.file);
  });

  return obj;
};
