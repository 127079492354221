import { modalsTypes } from './modalsTypes';

const SHOW_MODAL = 'MODALS/SHOW_MODAL';
const CLOSE_MODAL = 'MODALS/CLOSE_MODAL';

const modals = {};
for (let key in modalsTypes) {
  modals[key] = {
    visible: false,
    data: null
  }
}

const initialState = modals;

export const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state, [action.payload.type]: {
          visible: true,
          data: action.payload.data || ''
        }
      };
    case CLOSE_MODAL:
      return {
        ...state, [action.payload.type]: {
          visible: false,
          data: action.payload.data || ''
        }
      };
    default:
      return state;
  }
};

export const modalsActions = {
  showModal(payload) {
    return {
      type: SHOW_MODAL,
      payload
    }
  },

  closeModal(payload) {
    return {
      type: CLOSE_MODAL,
      payload
    }
  },
};
