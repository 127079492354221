export const particleCopyObject = (obj, path) => {
  const keys = path.split('.').filter(el => el !== '');
  const copy = {...obj};

  let currentCopyFolder = copy;
  let currentOriginalFolder = obj;

  for (let i = 0; i < keys.length; i++) {
    currentCopyFolder[keys[i]] = {...currentOriginalFolder[keys[i]]};
    currentCopyFolder = currentCopyFolder[keys[i]];
    currentOriginalFolder = currentOriginalFolder[keys[i]];
  }

  return copy;
};
