export const modalsTypes = {
  ERROR: 'ERROR',
  LANGUAGE: 'LANGUAGE',
  TRANSLATION: 'TRANSLATION',
  NOTIFICATION: 'NOTIFICATION',
  LOGIN: 'LOGIN',
  EDIT_TRANSLATION: 'EDIT_TRANSLATION',
  EXPORT_FILES_TO: 'EXPORT_FILES_TO',
  IMPORT_JSON: 'IMPORT_JSON',
  CONFIRM: 'CONFIRM',
  JSON_COMPARE: 'JSON_COMPARE',
  CREATE_COLLECTION: 'CREATE_COLLECTION',
};
