export const removeTranslation = (file, path) => {
  let pathArray = path.split('.');
  const folders = pathArray.slice(0, pathArray.length - 1);
  const key = pathArray[pathArray.length - 1];
  let currentFolder = file;
  for (let i = 0; i < folders.length; i++) {
    if (!currentFolder[pathArray[i]]) continue;
    currentFolder = currentFolder[pathArray[i]];
  }
  delete currentFolder[key];
  return file;
};
