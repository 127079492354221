import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsActions } from '../../../redux/modals/reducer';

export default React.memo(function NotificationModal() {
  const dispatch = useDispatch();
  const modalInfo = useSelector(getModalInfo(modalsTypes.NOTIFICATION));

  const close = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.NOTIFICATION }));
  };

  return !modalInfo.visible ? null : (
    <div className={'modal_container modal_container_error'}>
      <div className={'modal'}>
        <span className={'modal_title'}>
          Notification
        </span>
        <div className={'modal_body'}>
          {modalInfo.data}
        </div>
        <span onClick={close} className={'button'}>
          Ok
        </span>
      </div>
    </div>
  );
})
