import { addLanguage } from '../../utils/addLanguage';
import { findElementByPath } from '../../utils/findElementByPath';
import { editLanguage } from '../../utils/editLanguage';
import { addTranslation } from '../../utils/addTranslation';
import { removeTranslation } from '../../utils/removeTranslation';
import { removeLanguage } from '../../utils/removeLanguage';
import { parseLanguages } from '../../utils/parseLanguages';
import { deepCopyObject } from '../../utils/deepCopyObject';
import { merge } from '../../utils/merge';
import { particleCopyObject } from '../../utils/particleCopyObject';
import { importJson } from '../../utils/importJson';
import { insertObjectByPath } from '../../utils/insertObjectByPath';

const SET_FILE = 'APP/SET_FILE';
const SET_SELECTED_GAME = 'APP/SET_SELECTED_GAME';
const SET_GAMES_LIST = 'APP/SET_GAMES_LIST';
const SET_SELECTED_TRANSLATIONS = 'APP/SET_SELECTED_TRANSLATIONS';
const SET_SELECTED_FOLDER = 'APP/SET_SELECTED_FOLDER';
const CHANGE_TRANSLATION = 'APP/CHANGE_TRANSLATION';
const ADD_TRANSLATION = 'APP/ADD_TRANSLATION';
const REMOVE_TRANSLATION = 'APP/REMOVE_TRANSLATION';
const EDIT_TRANSLATION = 'APP/EDIT_TRANSLATION';
const IMPORT_JSON = 'APP/IMPORT_JSON';
const ADD_LANGUAGE = 'APP/ADD_LANGUAGE';
const SET_DEFAULT_LANGUAGE = 'APP/SET_DEFAULT_LANGUAGE';
const EDIT_LANGUAGE = 'APP/EDIT_LANGUAGE';
const REMOVE_LANGUAGE = 'APP/REMOVE_LANGUAGE';
const SET_MERGE_FILES = 'APP/SET_MERGE_FILES';
const SET_DIRTY = 'APP/SET_DIRTY';
const UNDO = 'APP/UNDO';
const REDO = 'APP/REDO';
const RESET_STATE = 'APP/RESET_STATE';
const historyLength = 10;

const initialState = {
  gamesList: [],
  file: {},
  mergeFiles: null,
  selectedTranslations: [],
  selectedFolders: [],
  languages: {
    list: [],
    default: ''
  },
  selectedGame: '',
  history: [],
  historyState: 0,
  isDirty: false,
};

export const appReducer = (state = initialState, action) => {
  console.log(action);
  let newState;
  let historyState;
  let file;
  let languages;
  switch (action.type) {
    case SET_SELECTED_GAME:
      return {
        ...state,
        selectedGame: action.payload
      };

    case SET_FILE:
      const newFile = state.mergeFiles ? merge(action.payload, state.mergeFiles) : action.payload;
      languages = parseLanguages(newFile);
      languages.forEach(el => addLanguage(action.payload, el));
      return {
        ...state,
        file: newFile,
        languages: {
          ...state.languages,
          list: languages,
        },
        mergeFiles: null,
      };

    case SET_GAMES_LIST:
      return {
        ...state,
        gamesList: action.payload,
        history: state.history.map(history => ({...history, state: {...history.state, gamesList: action.payload }}))
      };

    case SET_SELECTED_TRANSLATIONS:
      return {
        ...state,
        selectedTranslations: action.payload,
      };

    case SET_SELECTED_FOLDER:
      newState = {
        ...state,
        selectedFolders: action.payload,
        historyState: 0,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Select folder'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case CHANGE_TRANSLATION:
      file = particleCopyObject(state.file, action.payload.path);
      Object.assign(findElementByPath(file, action.payload.path), action.payload.translations);
      newState = {
        ...state,
        file: file,
        historyState: 0,
        isDirty: true,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Change translation'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case ADD_TRANSLATION:
      newState = {
        ...state,
        file: addTranslation(deepCopyObject(state.file), action.payload, state.languages.list),
        historyState: 0,
        isDirty: true,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Add translation'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case REMOVE_TRANSLATION:
      newState = {
        ...state,
        file: removeTranslation(deepCopyObject(state.file), action.payload),
        selectedTranslations: [],
        selectedFolders: [],
        historyState: 0,
        isDirty: true,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Remove translation'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case EDIT_TRANSLATION:
      newState = editTranslation(state, action);
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Edit translation'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case IMPORT_JSON:
      newState = importFile(state, action);
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Import json'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case ADD_LANGUAGE:
      newState = {
        ...state,
        languages: { ...state.languages, list: [...state.languages.list, action.payload] },
        file: addLanguage(deepCopyObject(state.file), action.payload),
        historyState: 0,
        isDirty: true,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Add language'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case REMOVE_LANGUAGE:
      newState = {
        ...state, file: removeLanguage(deepCopyObject(state.file), action.payload),
        languages: { ...state.languages, list: state.languages.list.filter(el => el !== action.payload) },
        historyState: 0,
        isDirty: true,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Remove language'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case SET_DEFAULT_LANGUAGE:
      newState = {
        ...state,
        languages: { ...state.languages, default: action.payload },
        historyState: 0,
      };
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Set default language'
        }, ...state.history.slice(state.historyState)].slice(0, historyLength),
      };

    case EDIT_LANGUAGE:
      newState = editLang(state, action);
      return {
        ...newState,
        history: [{
          state: { ...newState },
          tip: 'Edit language'
        }, ...state.history].slice(state.historyState, historyLength),
      };

    case SET_MERGE_FILES:
      return {
        ...state,
        mergeFiles: action.payload
      };

    case SET_DIRTY:
      return {
        ...state,
        isDirty: action.payload
      };

    case RESET_STATE:
      return {
        ...state,
        history: [],
        historyState: 0,
        selectedTranslations: [],
        selectedFolders: [],
        file: {},
        selectedGame: '',
        languages: { list: [], default: '' },
        isDirty: !!state.mergeFiles
      };

    case UNDO:
      const prevState = state.history[state.historyState + 1]?.state;
      historyState = state.historyState < state.history.length - 1 ? ++state.historyState : state.historyState;
      return {
        ...prevState,
        history: state.history,
        historyState: historyState,
        selectedTranslations: state.selectedTranslations
      };

    case REDO:
      const nextState = state.history[state.historyState - 1]?.state;
      historyState = state.historyState > 0 ? --state.historyState : state.historyState;
      return { ...nextState, history: state.history, historyState: historyState };

    default:
      return state;
  }
};

const editTranslation = (state, action) => {
  let fileCopy = deepCopyObject(state.file);
  let obj = findElementByPath(fileCopy, action.payload.path);
  let resultObj = obj;

  if (action.payload.new.key !== action.payload.oldKey) {
    let entries = Object.entries(obj);
    entries = entries.map(el => {
      if (el[0] === action.payload.oldKey) {
        el[0] = action.payload.new.key;
      }
      return el;
    });

    resultObj = Object.fromEntries(entries);

    if (!action.payload.path.length) {
      fileCopy = resultObj;
    } else {
      insertObjectByPath(fileCopy, action.payload.path, resultObj);
    }
  }

  if (action.payload.new.tip !== resultObj[action.payload.new.key].tip) {
    resultObj[action.payload.new.key].tip = action.payload.new.tip;
  }

  const folderIndex = state.selectedFolders.indexOf(action.payload.path + '.' + action.payload.oldKey);
  const folders = folderIndex >= 0 ?
    [...state.selectedFolders.slice(0, folderIndex),
      action.payload.path + '.' + action.payload.new.key,
      ...state.selectedFolders.slice(folderIndex + 1)] : state.selectedFolders;
  const translationIndex = state.selectedTranslations.indexOf(action.payload.path + '.' + action.payload.oldKey);
  let newSelectedTranslations = [...state.selectedTranslations];

  if (translationIndex >= 0) {
    newSelectedTranslations = [
      ...state.selectedTranslations.slice(0, translationIndex + 1),
      action.payload.path + '.' + action.payload.new.key,
      ...state.selectedTranslations.slice(translationIndex + 1)
    ];
  }

  return {
    ...state,
    file: fileCopy,
    selectedFolders: folders,
    selectedTranslations: newSelectedTranslations,
    historyState: 0,
    isDirty: true,
  };
};

const importFile = (state, action) => {
  const file = importJson(action.payload.file, action.payload.path, action.payload.json, action.payload.language);
  const languages = state.languages.list.includes(action.payload.language) ? state.languages.list : parseLanguages(file);
  const path = action.payload.path ?
    ((action.payload.path[0] === '.' && action.payload.path.length > 1) ? action.payload.path : '.' + action.payload.path) : state.selectedGame;

  return {
    ...state,
    file: file,
    languages: {
      ...state.languages,
      list: languages,
    },
    selectedTranslations: [],
    selectedFolders: [path],
    historyState: 0,
    isDirty: true,
  };
};

const editLang = (state, action) => {
  const defaultLanguage = action.payload.langName === state.languages.default ? action.payload.newLangName : state.languages.default;
  const langIndex = state.languages.list.indexOf(action.payload.langName);
  const langList = langIndex < 0 ? state.languages.list : [
    ...state.languages.list.slice(0, langIndex), action.payload.newLangName,
    ...state.languages.list.slice(langIndex + 1)
  ];

  return {
    ...state,
    languages: {
      default: defaultLanguage,
      list: langList,
    },
    file: editLanguage(deepCopyObject(state.file), action.payload.langName, action.payload.newLangName),
    historyState: 0,
    isDirty: true,
  };
};

export const appActions = {

  setSelectedGame(payload) {
    return {
      type: SET_SELECTED_GAME,
      payload
    }
  },

  setFile(payload) {
    return {
      type: SET_FILE,
      payload
    }
  },

  setGamesList(payload) {
    return {
      type: SET_GAMES_LIST,
      payload
    }
  },

  setSelectedTranslations(payload) {
    return {
      type: SET_SELECTED_TRANSLATIONS,
      payload
    }
  },

  setSelectedFolders(payload) {
    return {
      type: SET_SELECTED_FOLDER,
      payload
    }
  },

  changeTranslation(payload) {
    return {
      type: CHANGE_TRANSLATION,
      payload
    }
  },

  addTranslation(payload) {
    return {
      type: ADD_TRANSLATION,
      payload
    }
  },

  removeTranslation(payload) {
    return {
      type: REMOVE_TRANSLATION,
      payload
    }
  },

  editTranslation(payload) {
    return {
      type: EDIT_TRANSLATION,
      payload
    }
  },

  importJson(payload) {
    return {
      type: IMPORT_JSON,
      payload
    }
  },

  addLanguage(payload) {
    return {
      type: ADD_LANGUAGE,
      payload
    }
  },

  setDefaultLanguage(payload) {
    return {
      type: SET_DEFAULT_LANGUAGE,
      payload
    }
  },

  editLanguage(payload) {
    return {
      type: EDIT_LANGUAGE,
      payload
    }
  },

  removeLanguage(payload) {
    return {
      type: REMOVE_LANGUAGE,
      payload
    }
  },

  setMergeFiles(payload) {
    return {
      type: SET_MERGE_FILES,
      payload
    }
  },

  undo() {
    return {
      type: UNDO,
    }
  },

  redo() {
    return {
      type: REDO,
    }
  },

  setDirty(payload) {
    return {
      type: SET_DIRTY,
      payload
    }
  },

  resetState() {
    return {
      type: RESET_STATE
    }
  }
};
