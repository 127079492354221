export const insertObjectByPath = (obj, path, data) => {
  let pathArray = path.split('.');
  pathArray = pathArray.filter(el => el !== '');
  let result = obj;
  for (let i = 0; i < pathArray.length; i++) {

    if (i === pathArray.length - 1) {
      result[pathArray[i]] = data;
      return;
    }

    if (!result.hasOwnProperty(pathArray[i])) {
      result[pathArray[i]] = {};
    }

    result = result[pathArray[i]];
  }
};
