export const parseLanguages = (file) => {
  const languages = [];
  const checkFile = (obj, result) => {
    for (let key in obj) {
      if (key === 'tip') continue;
      if (typeof obj[key] === 'object') {
        checkFile(obj[key], result);
        continue;
      }
      if (result.includes(key)) continue;

      result.push(key);
    }
  };

  checkFile(file, languages);
  return languages;
};
