import React, { useEffect, useMemo, useState } from 'react';
import styles from './translationModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsActions } from '../../../redux/modals/reducer';
import { getFile, getSelectedGame } from '../../../redux/app/selectors';
import { findElementByPath } from '../../../utils/findElementByPath';
import { appActions } from '../../../redux/app/reducer';

import { ReactComponent as AddTranslationIcon } from '../../../assets/icons/add-translation-icon.svg';

export default React.memo(function TranslationModal({ selectedFolders }) {
  const dispatch = useDispatch();
  const file = useSelector(getFile);
  const selectedGame = useSelector(getSelectedGame);
  const modalInfo = useSelector(getModalInfo(modalsTypes.TRANSLATION));
  const [inputValues, setInputValues] = useState(['']);

  const folderPath = useMemo(() => {
    let path = '';
    if (selectedFolders.length === 1) {
      path = selectedFolders.includes(selectedGame) ? '' : selectedFolders[0] + '.'
    }
    return path;
  }, [selectedFolders, selectedGame]);

  useEffect(() => {
      setInputValues([folderPath]);
  }, [folderPath]);

  const onInputChange = (index, value) => {
    setInputValues(prev => {
      return [...prev.slice(0, index), value, ...prev.slice(index + 1)];
    });
  };

  const onAddInput = () => {
    setInputValues(prev => [...prev, folderPath]);
  };

  const onSubmit = () => {
    inputValues.forEach(inputValue => {
      if (!inputValue) return;
      if (findElementByPath(file, inputValue)) {
        return dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: 'Translation already exist!' }));
      }

      dispatch(appActions.addTranslation(inputValue));
    });

    setInputValues([]);
    close();
  };

  const close = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.TRANSLATION }));
  };

  return !modalInfo.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal'}>
        <span className={'modal_title'}>
          ADD TRANSLATION
        </span>
        <div className={styles.container}>
        <span className={styles.tip}>
          Please, enter name for the translation.
          <br />
          Use '.' to create hierarchical structure, e.g. dashboard.title.label
        </span>
          <div>
            {inputValues.map((el, i) =>
              <input key={i} autoFocus value={el} onChange={(e) => onInputChange(i, e.currentTarget.value)} />
            )}
            <AddTranslationIcon onClick={onAddInput} />
          </div>
          <div>
        <span onClick={close} className={'button'}>
          Cancel
        </span>
            <span onClick={onSubmit} className={'button'}>
          Create
        </span>
          </div>
        </div>
      </div>
    </div>
  );
})
