import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { modalsActions } from '../../../redux/modals/reducer';
import styles from './index.module.css';
import { validateBeforeInput } from '../../../utils/validateBeforeInput';
import { managerRest } from '../../../index';

export default React.memo(function CreateCollectionModal() {
  const dispatch = useDispatch();
  const modal = useSelector(getModalInfo(modalsTypes.CREATE_COLLECTION));
  const [loading, setLoading] = useState(false);
  const [collectionName, setCollectionName] = useState('');

  useEffect(() => {
    setCollectionName('');
  }, [modal.visible]);

  const close = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.CREATE_COLLECTION }));
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const success = managerRest.createCollection(collectionName);
    setLoading(false);
    if (success) close();
  }

  const handleInputChange = (e) => {
    setCollectionName(e.target.value);
  }

  const validateInput = (e) => {
    validateBeforeInput(e, /^[a-zA-Z0-9_]+$/);
  }

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal confirm_modal'}>
        <span className={'modal_title'}>Create Collection</span>
        <div className={'modal_body'}>
          <form onSubmit={submit} className={styles.form}>
            <label className={styles.form_group}>
              <span>Collection name:</span>
              <input value={collectionName} onChange={handleInputChange} onBeforeInput={validateInput} required />
            </label>

            <div className={styles.form_controls}>
              <button type={'button'} onClick={close} className={styles.form_controls__cancel}>
                Cancel
              </button>
              <button type={'submit'} className={styles.form_controls__submit} disabled={loading}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
})
