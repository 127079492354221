import React, { useCallback, useState } from 'react';
import styles from './exportFilesModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { getFile, getFilesList, getSelectedGame } from '../../../redux/app/selectors';
import { modalsActions } from '../../../redux/modals/reducer';
import { managerRest } from '../../../index';
import { appActions } from '../../../redux/app/reducer';
import { createConfirmModal } from '../../../utils/createConfirmModal';

export default React.memo(function ExportFilesModal() {
  const modal = useSelector(getModalInfo(modalsTypes.EXPORT_FILES_TO));
  const dispatch = useDispatch();
  const json = useSelector(getFile);
  const filesList = useSelector(getFilesList);
  const currentGame = useSelector(getSelectedGame);
  const [selected, setSelected] = useState('');

  const onSelect = (fileName) => {
    setSelected(fileName);
  };

  const onCancel = () => {
    dispatch(appActions.setMergeFiles(null));
    dispatch(modalsActions.closeModal({ type: modalsTypes.EXPORT_FILES_TO }));
  };

  const onOkClick = () => {
    dispatch(modalsActions.showModal(
      createConfirmModal(null, onSubmitFirstConfirm,
        'Cancel', 'Submit',
        `Are you sure you want to export selected files to "${selected}" directory ?`)
    ));
  };

  const onSubmitFirstConfirm = async () => {
      dispatch(modalsActions.showModal(createConfirmModal(
        onDontSave, onSaveConfirm, `Don't save`, 'Save',
        'Did you want to save changes in current working directory ?',
        'Cancel', onCancelSecondConfirm)
      ));
  };

  const onCancelSecondConfirm = async () => {
      dispatch(modalsActions.showModal(
        createConfirmModal(null, onSubmitFirstConfirm,
          'Cancel', 'Submit',
          `Are you sure you want to export selected files to "${selected}" directory ?`)
      ));
  };

  const onDontSave = useCallback(async () => {
    dispatch(appActions.resetState());
    dispatch(appActions.setSelectedGame(selected));
    setSelected('');
    dispatch(modalsActions.closeModal({ type: modalsTypes.EXPORT_FILES_TO }));
  }, [selected, dispatch]);

  const onSaveConfirm = useCallback(async () => {
    const success = await managerRest.saveTranslation(currentGame, json);
    success && onDontSave();
  }, [currentGame, json, onDontSave]);

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal'}>
        <span className={'modal_title'}>
          Export selected translations to:
        </span>
        <div className={'modal_body'}>
          <div className={styles.files_list}>
            {filesList.map(el =>
              <div onClick={() => onSelect(el)} key={el} className={el === selected ? styles.active : ''}>
                {el}
              </div>
            )}
          </div>
        </div>
        <div className={'modal_controls'}>
          <span onClick={onCancel} className={'button'}>Cancel</span>
          <span onClick={onOkClick} className={'button'}>Ok</span>
        </div>
      </div>
    </div>
  );
})
