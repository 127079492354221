import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './header.module.css';

import { ReactComponent as SaveIcon } from '../../assets/icons/save-icon.svg';
import { ReactComponent as AddLangIcon } from '../../assets/icons/add-lang-icon.svg';
import { ReactComponent as AddTranslationIcon } from '../../assets/icons/add-translation-icon.svg';
import { ReactComponent as CreateCollectionIcon } from '../../assets/icons/document_create.svg';
import { ReactComponent as RemoveTranslationIcon } from '../../assets/icons/remove-translation-icon.svg';
import { ReactComponent as UndoIcon } from '../../assets/icons/undo-icon.svg';
import { ReactComponent as RedoIcon } from '../../assets/icons/redo-icon.svg';

import { modalsActions } from '../../redux/modals/reducer';
import { modalsTypes } from '../../redux/modals/modalsTypes';
import { appActions } from '../../redux/app/reducer';
import { getFile, getHistory, getHistoryState, getSelectedGame } from '../../redux/app/selectors';
import { managerRest } from '../../index';
import { createConfirmModal } from '../../utils/createConfirmModal';

export default React.memo(function Header({
                                            selectedTranslations,
                                            setSearchTerm,
                                            searchTerm,
                                            selectedFolders,
                                            saveCallback
                                          }) {
  const dispatch = useDispatch();
  const file = useSelector(getFile);
  const history = useSelector(getHistory);
  const selectedGame = useSelector(getSelectedGame);
  const historyState = useSelector(getHistoryState);
  const [searchInput, setSearchInput] = useState('');
  console.log('history : ', history);
  console.log('history state : ', historyState);
  const hasFile = file && !!Object.keys(file).length;

  useEffect(() => {
    setSearchInput(searchTerm);
  }, [searchTerm]);

  const onChangeTerm = (e) => {
    setSearchInput(e.currentTarget.value);
  };

  const onSearch = () => {
    if (!hasFile) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please upload your translation file!'
      }))
    }
    setSearchTerm(searchInput);
  };

  const onSave = () => {
    if (!hasFile) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please upload your translation file!'
      }))
    }

    dispatch(modalsActions.showModal(
      createConfirmModal(null, saveCallback, 'Cancel', 'Save', 'Save this file on server ?')
    ));
  };

  const onOpenLanguageManager = () => {
    if (!hasFile) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please upload your translation file!'
      }))
    }
    dispatch(modalsActions.showModal({ type: modalsTypes.LANGUAGE }));
  };

  const openCreateCollectionModal = () => {
    dispatch(modalsActions.showModal({ type: modalsTypes.CREATE_COLLECTION }));
  };

  const onAddTranslation = () => {
    if (!hasFile) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please upload your translation file!'
      }))
    }
    dispatch(modalsActions.showModal({
      type: modalsTypes.TRANSLATION
    }));
  };

  const deleteTranslations = useCallback(async () => {
    const canDelete = await managerRest.deleteTranslations(selectedGame, selectedFolders);
    canDelete && selectedFolders.forEach(selectedFolder => dispatch(appActions.removeTranslation(selectedFolder)));
  }, [selectedGame, selectedFolders]);

  const onRemoveTranslation = async () => {
    if (!hasFile) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please upload your translation file!'
      }))
    }
    if (!selectedTranslations.length && !selectedFolders.length) {
      return dispatch(modalsActions.showModal({
        type: modalsTypes.ERROR,
        data: 'Please, select translations to remove!'
      }))
    }

    dispatch(modalsActions.showModal(createConfirmModal(
      null, deleteTranslations, 'Cancel',
      'Delete', 'Are you sure you want to delete this translations? Document will be changed on server.')));
  };

  const onUndo = () => {
    if (!history.length || historyState === history.length - 1) return;
    dispatch(appActions.undo());
  };

  const onRedo = () => {
    if (historyState === 0) return;
    dispatch(appActions.redo());
  };

  return (
    <div className={styles.container}>

      {/*search*/}
      <div className={styles.search_box}>
        <input placeholder={'Search...'} value={searchInput} onChange={onChangeTerm} onBlur={onSearch}
               className={styles.search_input} />
      </div>

      {/*controls*/}
      <div className={styles.controls}>
        <button onClick={onSave} className={`${styles.button_box} ${styles.main_buttons}`}>
          <SaveIcon />
          <span>
            Save
          </span>
        </button>
        <button onClick={onOpenLanguageManager} className={`${styles.button_box} ${styles.main_buttons}`}>
          <AddLangIcon />
          <span>
           Languages
          </span>
        </button>
        <button onClick={onAddTranslation} className={`${styles.button_box} ${styles.main_buttons}`}>
          <AddTranslationIcon />
          <span>
            Add translation
          </span>
        </button>
        <button onClick={onRemoveTranslation} className={`${styles.button_box} ${styles.main_buttons}`}>
          <RemoveTranslationIcon />
          <span>
            Remove translation
          </span>
        </button>
        <button onClick={onUndo} title={history[historyState]?.tip}
                className={`${styles.button_box} ${(!history.length || historyState === history.length - 1) ? styles.disabled : ''}`}>
          <UndoIcon />
          <span>
            Undo
          </span>
        </button>
        <button onClick={onRedo} title={history[historyState - 1]?.tip}
                className={`${styles.button_box} ${historyState === 0 ? styles.disabled : ''}`}>
          <RedoIcon />
          <span>
            Redo
          </span>
        </button>
        <button onClick={openCreateCollectionModal} className={`${styles.button_box} ${styles.main_buttons}`}>
          <CreateCollectionIcon />
          <span>
            Create Collection
          </span>
        </button>
      </div>
    </div>
  );
})
