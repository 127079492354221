import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { managerRest } from '../../../index';

export default React.memo(function LoginModal() {
  const modal = useSelector(getModalInfo(modalsTypes.LOGIN));
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.currentTarget.id]: e.currentTarget.value });
  };

  const login = useCallback(() => {
    managerRest.login(inputs);
  }, [inputs]);

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      login();
    }
  }, [login]);

  useEffect(() => {
    if (modal.visible) {
      document.addEventListener('keydown', onKeyDown);
    } else {
      document.removeEventListener('keydown', onKeyDown);
    }
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [modal.visible, onKeyDown]);

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal'}>
        <span className={'modal_title'}>
          Login
        </span>

        <div className={'modal_body'}>
          <div className={'modal_form'}>
            <input onChange={onInputChange} value={inputs.username} id={'username'} placeholder={'Login'} />

            <input onChange={onInputChange} value={inputs.password} type={'password'} id={'password'}
                   placeholder={'Password'} />
          </div>
        </div>

        <div className={'modal_controls'}>
          <span onClick={login} className={'button'}>Login</span>
        </div>
      </div>
    </div>
  );
})
