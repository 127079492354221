export const findAllTranslationsInFolder = (file, folderPath, languages) => {
  const result = [];
  let pathArray = folderPath.split('.');
  let currentFolder = file;
  for (let i = 0; i < pathArray.length; i++) {
    if (!currentFolder[pathArray[i]]) continue;
    currentFolder = currentFolder[pathArray[i]]
  }

  getTranslationsPaths(currentFolder, folderPath, languages, result);
  return result;
};

const getTranslationsPaths = (folder, path, languages, result) => {
  let keys = Object.keys(folder);
  for (let i = 0; i < keys.length; i++) {
    let currentPath = path;
    if (typeof folder[keys[i]] === 'object') {
      currentPath += '.' + keys[i];
      getTranslationsPaths(folder[keys[i]], currentPath, languages, result);
      continue;
    }
    if (languages.includes(keys[i])) {
      if (!result.includes(currentPath))
        result.push(currentPath);
    }
  }
};
