import React, { useCallback, useMemo, useRef } from 'react';
import * as Diff from 'diff';
import { useDispatch, useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { changeKeysToLanguage, importJson } from '../../../utils/importJson';
import { deepCopyObject } from '../../../utils/deepCopyObject';
import { modalsActions } from '../../../redux/modals/reducer';
import { appActions } from '../../../redux/app/reducer';
import { getFile, getLanguages } from '../../../redux/app/selectors';
import { addLanguage } from '../../../utils/addLanguage';
import { compareModes } from './compareModes';
import { removeEmpty } from '../../../utils/removeEmpty';

export default React.memo(function JsonCompareModal({ saveCallback }) {
  const dispatch = useDispatch();
  const json = useSelector(getFile);
  const modal = useSelector(getModalInfo(modalsTypes.JSON_COMPARE));
  const languages = useSelector(getLanguages);
  const diff = useRef([]);
  let diffOffset = 10;

  const data = useMemo(() => modal.data || {}, [modal.data]);

  const onCancel = useCallback(() => {
    diff.current = [];
    dispatch(modalsActions.closeModal({ type: modalsTypes.JSON_COMPARE }))
  }, [dispatch]);

  const onImport = useCallback(() => {
    const jsonCopy = deepCopyObject(json);
    changeKeysToLanguage(data.import, data.language);
    addLanguage(jsonCopy, data.language);
    languages.list.forEach(el => addLanguage(data.import, el));
    dispatch(appActions.importJson({ file: jsonCopy, json: data.import, path: data.path, language: data.language }));
    onCancel();
  }, [json, languages, data, dispatch, onCancel]);

  const onSave = useCallback(() => {
    saveCallback(data.nextFolder);
    onCancel();
  }, [data.nextFolder, saveCallback, onCancel]);

  const callback = useCallback(() => {
    switch (data.mode) {
      case compareModes.IMPORT:
        return onImport();
      case compareModes.SAVE:
        return onSave();
      default: return () => {}
    }
  }, [data.mode, onImport, onSave]);

  if (data.target && data.import && !diff.current.length) {
    let targetCopy = deepCopyObject(data.target);
    let importCopy = deepCopyObject(data.import);

    changeKeysToLanguage(importCopy, data.language);
    addLanguage(targetCopy, data.language);
    languages.list.forEach(el => addLanguage(importCopy, el));

    if (data.mode === compareModes.IMPORT) {
      targetCopy = importJson(targetCopy, '', importCopy);
      diff.current = Diff.diffJson(data.target, targetCopy);
    }

    if (data.mode === compareModes.SAVE) {
      removeEmpty(importCopy, languages.list);
      diff.current = Diff.diffJson(data.target, importCopy);
    }

    if (diff.current.length === 1 && !diff.current[0].added && !diff.current[0].removed) {
      callback();
    }

    diff.current.forEach((el, i) => {
      if (!el.hasOwnProperty('added') && !el.hasOwnProperty('removed') && el.count > diffOffset) {
        let stringsArray = el.value.split('\n');
        let start = i === 0 ? '' : stringsArray.slice(0, diffOffset).join('\n');
        let end = i === diff.current.length - 1 ? '' : stringsArray.slice(stringsArray.length - diffOffset - 1).join('\n');

        el.value = [start, <div key={i} className={'json_compare_modal_collapse_placeholder'}>...</div>, end];
      }
    });

    diff.current = diff.current.map((part, i) => {
      return (
        <span key={i}
              style={{ backgroundColor: part.added ? 'rgba(0, 255, 0, 0.4)' : part.removed ? 'rgba(255, 0, 0, 0.4)' : 'whitesmoke' }}>
            {part.value}
          </span>
      )
    });
  }

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal json_compare_modal'}>
        <span className={'modal_title'}>
          COMPARE
        </span>

        <div className={'modal_body json_compare_body scroll'}>
          {diff.current}
        </div>

        <div className={'modal_controls'}>
          <span onClick={onCancel} className={'button'}>Cancel</span>
          <span onClick={callback} className={'button'}>{data.mode}</span>
        </div>

      </div>
    </div>
  );
})
