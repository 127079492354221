import React, { useCallback, useState } from 'react';
import styles from './languageModal.module.css';
import EditModal from './editModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { modalsActions } from '../../../redux/modals/reducer';
import { getLanguages } from '../../../redux/app/selectors';
import { appActions } from '../../../redux/app/reducer';

export default React.memo(function LanguageModal() {
  const dispatch = useDispatch();
  const modalInfo = useSelector(getModalInfo(modalsTypes.LANGUAGE));
  const languages = useSelector(getLanguages);
  const [languageInput, setLanguageInput] = useState('');
  const [showEditWindow, setShowEditWindow] = useState(false);
  const [selected, setSelected] = useState('');

  const close = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.LANGUAGE }));
  };

  const onChangeInput = (e) => {
    setLanguageInput(e.currentTarget.value);
  };

  const addLanguage = () => {
    if (!languageInput) return;
    if (languages.list.includes(languageInput)) {
      return dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: 'This language already exist!' }));
    }
    dispatch(appActions.addLanguage(languageInput));
    setLanguageInput('');
  };

  const onEdit = useCallback((el) => {
    setSelected(el);
    setShowEditWindow(true);
  }, []);

  return !modalInfo.visible ? null : (
    <div className={'modal_container'}>
      {showEditWindow ?
        <EditModal selected={selected} languages={languages}
                   setShowEditWindow={setShowEditWindow} setSelected={setSelected} />
        :
        <div className={'modal'}>
          <span className={'modal_title'}>LANGUAGES</span>
          <div className={styles.container}>
            <div className={styles.languages_box}>
              {languages.list.map(el =>
                <div key={el}>
                  {el}
                  {el === languages.default && <span className={styles.default_flag}>- default</span>}
                  <span onClick={() => onEdit(el)} className={styles.edit}>Edit</span>
                </div>
              )}
            </div>
            <div className={styles.controls}>
              <label>Add language:</label>
              <div>
                <input value={languageInput} onChange={onChangeInput} />
                <span onClick={addLanguage} className={'button'}>Add</span>
              </div>
            </div>
          </div>
          <span onClick={close} className={`button`}>
          Ok
        </span>
        </div>
      }
    </div>
  );
})
