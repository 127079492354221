export const removeEmpty = (file, languages) => {
  for (let key in file) {

    if (typeof file[key] === 'object') {
      file[key] = removeEmpty(file[key], languages);
      continue;
    }

    if ((key === 'tip' || languages.includes(key)) && file[key] === '') {
      delete file[key];
    }
  }

  return file;
};
