import { appActions } from '../redux/app/reducer';
import { modalsActions } from '../redux/modals/reducer';
import { modalsTypes } from '../redux/modals/modalsTypes';
import { userActions } from '../redux/user/reducer';

export class ManagerRest {
  constructor(store) {
    this.store = store;
    this.domain = `https://${window.location.hostname}/api`;
    if ((/(localhost|192.168.1.54)/i).test(this.domain))
      this.domain = `http://localhost:3000/api`;
    this.token = localStorage.getItem('translation_service_token');
    if (this.token) {
      this.checkAuth();
    } else {
      this.store.dispatch(modalsActions.showModal({ type: modalsTypes.LOGIN }));
    }
  }

  checkAuth = async () => {
    const data = {
      token: this.token
    };

    try {
      const response = await this.postRequest('/checkauth', data);
      if (response.success) {
        this.token = response.token;
        localStorage.setItem('translation_service_token', response.token);
        this.store.dispatch(userActions.setAuth(true));
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.LOGIN }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  login = async (data) => {
    try {
      const response = await this.postRequest('/login', data);
      if (response.success) {
        this.token = response.token;
        localStorage.setItem('translation_service_token', this.token);
        this.store.dispatch(userActions.setAuth(true));
        this.store.dispatch(userActions.setUser({ username: data.login }));
        this.store.dispatch(modalsActions.closeModal({ type: modalsTypes.LOGIN }));
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  getGamesList = async () => {
    try {
      const response = await this.getRequest('/games');
      if (response.success) {
        this.store.dispatch(appActions.setGamesList(response.data));
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  getGameTranslation = async (game, saveToStore) => {
    try {
      const response = await this.getRequest(`/translations?game=${game}`);
      if (response.success) {

        if (saveToStore) {
          this.store.dispatch(appActions.setFile(response.data));
          this.store.dispatch(appActions.setSelectedFolders([game]));
        }

        return response.data;
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  saveTranslation = async (game, translation) => {
    const data = {
      game: game,
      data: translation
    };

    try {
      const response = await this.postRequest('/save', data);
      if (response.success) {
        this.store.dispatch(appActions.setDirty(false));
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.NOTIFICATION, data: 'Saved!' }));
        return response.success;
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  deleteTranslations = async (game, translations) => {
    let keys = [...translations];
    keys.forEach((el, i) => {
      if (el[0] === '.') {
        keys[i] = el.slice(1);
      }
    });

    const data = {
      game: game,
      data: keys,
    };

    try {
      let response = await fetch(`${this.domain}/delete`, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
          'token': this.token || '',
        }
      });

      response = await response.json();

      if (response.success) {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.NOTIFICATION, data: 'Deleted!' }));
        return response.success;
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  createCollection = async (collectionName) => {
    const data = {
      name: collectionName,
    };

    try {
      const response = await this.postRequest('/create_collection', data);
      if (response.success) {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.NOTIFICATION, data: 'Collection created!' }));
        this.store.dispatch(appActions.setGamesList(response.data));
        return response.success;
      } else {
        this.store.dispatch(modalsActions.showModal({ type: modalsTypes.ERROR, data: response.message }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  postRequest = async (url, data) => {
    try {
      const response = await fetch(this.domain + url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
          'token': this.token || '',
        }
      });

      return await response.json();
    } catch (e) {
      console.error(e);
    }
  };

  getRequest = async (url) => {
    try {
      const response = await fetch(this.domain + url, {
        headers: {
          'token': this.token || '',
        }
      });
      return await response.json();
    } catch (e) {
      throw new Error(e.message);
    }
  };
}
