const SET_AUTH = 'USER/SET_AUTH';
const SET_USER = 'USER/SET_USER';

const initialState = {
  user: null,
  isAuth: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export const userActions = {
  setAuth(payload) {
    return {
      type: SET_AUTH,
      payload
    }
  },

  setUser(payload) {
    return {
      type: SET_USER,
      payload
    }
  },
};
