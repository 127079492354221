import React, { useEffect, useState } from 'react';
import styles from './importJsonModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getModalInfo } from '../../../redux/modals/selectors';
import { modalsTypes } from '../../../redux/modals/modalsTypes';
import { getFile, getSelectedFolders, getSelectedGame } from '../../../redux/app/selectors';
import { modalsActions } from '../../../redux/modals/reducer';
import { findElementByPath } from '../../../utils/findElementByPath';
import { createByPath } from '../../../utils/createByPath';
import { compareModes } from '../jsonCompareModal/compareModes';

export default React.memo(function ImportJsonModal() {
  const dispatch = useDispatch();
  const json = useSelector(getFile);
  const modal = useSelector(getModalInfo(modalsTypes.IMPORT_JSON));
  const selectedFolders = useSelector(getSelectedFolders);
  const selectedGame = useSelector(getSelectedGame);
  const [pathForImport, setPathForImport] = useState('');

  useEffect(() => {
    if (selectedFolders.length === 1 && selectedFolders[0] !== selectedGame) {
      setPathForImport(selectedFolders[0]);
    } else {
      setPathForImport('');
    }
  }, [selectedFolders, selectedGame]);

  const onInputChange = (e) => {
    setPathForImport(e.currentTarget.value);
  };

  const onCancelClick = () => {
    dispatch(modalsActions.closeModal({ type: modalsTypes.IMPORT_JSON }));
  };

  const onImport = () => {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const language = modal.data.name.slice(0, modal.data.name.lastIndexOf('.'));
      const target = findElementByPath(json, pathForImport) || createByPath(json, pathForImport);

      dispatch(modalsActions.showModal({
        type: modalsTypes.JSON_COMPARE,
        data: {
          target: target,
          import: JSON.parse(e.target.result),
          language: language,
          path: pathForImport,
          mode: compareModes.IMPORT
        }
      }));

      dispatch(modalsActions.closeModal({type: modalsTypes.IMPORT_JSON}));
    };

    fileReader.readAsText(modal.data);
  };

  return !modal.visible ? null : (
    <div className={'modal_container'}>
      <div className={'modal'}>
        <span className={'modal_title'}>
          Import json
        </span>
        <div className={'modal_body'}>
          <span className={styles.tip}>
            Please, enter name for the translation.
          <br />
            Use '.' to create hierarchical structure, e.g. dashboard.title.label
        </span>
          <input value={pathForImport} onChange={onInputChange} className={styles.input} />
        </div>

        <div className={'modal_controls'}>
          <span onClick={onCancelClick} className={'button'}>
            Cancel
          </span>
          <span onClick={onImport} className={'button'}>
            Ok
          </span>
        </div>
      </div>
    </div>
  );
})
