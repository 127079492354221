export const editLanguage = (file, langName, newLangName) => {
  for (let key in file) {
    if (key === langName) {
      file[newLangName] = file[langName];
      delete file[langName];
      continue;
    }
    if (typeof file[key] === 'object') {
      editLanguage(file[key], langName, newLangName);
    }
  }
  return file;
};
