import React, { useEffect, useMemo, useState } from 'react';
import styles from './folder.module.css';
import { appActions } from '../../redux/app/reducer';

import { ReactComponent as FolderIcon } from '../../assets/icons/folder-icon.svg';
import { ReactComponent as TranslationIcon } from '../../assets/icons/translation-icon.svg';
import { ReactComponent as TriangleIcon } from '../../assets/icons/triangle-icon.svg';
import { isFolderContainSearchTerm } from '../../utils/isFolderContainSearchTerm';
import { isFolder } from '../../utils/isItFolder';
import { useDispatch, useSelector } from 'react-redux';
import { getFile } from '../../redux/app/selectors';
import { findOneLevelSelectedFolders } from '../../utils/findOneLevelSelectedFolders';
import { calculateFulfilled } from '../../utils/calculateFulfilled';

export default function Folder({
                                 isItFolder, title, root, onRootFolderClick,
                                 path, selectedTranslations,
                                 selectedFolders, obj, searchTerm, languages,
                                 multiSelect, shiftSelect,
                               }) {

  const dispatch = useDispatch();
  const json = useSelector(getFile);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (!Object.keys(obj).length) {
      setShowDropdown(false);
    }
  }, [obj]);

  let isSelected;
  if (!isItFolder) {
    isSelected = selectedTranslations.filter(el => el === path).length;
  } else {
    isSelected = root ? selectedFolders.includes(root) : selectedFolders.includes(path);
  }

  const onSelect = () => {
    if (root) {
      return onRootFolderClick(root);
    }

    if (multiSelect) {
      if (selectedTranslations.includes(path) || selectedFolders.includes(path)) {
        dispatch(appActions.setSelectedFolders(selectedFolders.filter(el => el !== path)));
        return dispatch(appActions.setSelectedTranslations(selectedTranslations.filter(el => el !== path)));
      } else {
        dispatch(appActions.setSelectedFolders([...selectedFolders, path]));
        return dispatch(appActions.setSelectedTranslations([...selectedTranslations, path]));
      }
    }

    if (shiftSelect) {
      dispatch(appActions.setSelectedTranslations([]));
      if (selectedFolders.includes(path)) {
        dispatch(appActions.setSelectedFolders([]));
      } else {
        const addFolders = findOneLevelSelectedFolders(json, path, selectedFolders);
        return dispatch(appActions.setSelectedFolders([...selectedFolders, ...addFolders]))
      }
    }

    if (selectedFolders.length === 1 && selectedFolders[0] === path) return;

    dispatch(appActions.setSelectedTranslations([]));
    dispatch(appActions.setSelectedFolders([path]));
  };

  const onClickFolder = () => {
    if (root) {
      onSelect();
    }
    setShowDropdown(prev => !prev)
  };

  const fulfilled = useMemo(() => isItFolder ? null : calculateFulfilled(obj), [isItFolder, obj]);

  return (
    <>
      <div className={`${styles.container} ${isSelected ? styles.selected : ''}`}>
        <span onClick={onClickFolder} className={!isItFolder ? styles.hidden : showDropdown ? styles.open_folder : ''}>
          <TriangleIcon />
        </span>
        <div onClick={onSelect}>
          <span>
            {
              isItFolder ?
                <FolderIcon /> :
                <TranslationIcon />
            }
          </span>
          <span className={styles.title}>
            {title}
          </span>
          <span
            className={fulfilled !== null ? fulfilled < 50 ? styles.red : fulfilled < 100 ? styles.orange : fulfilled === 100 ? styles.green : '' : ''}>
            {fulfilled !== null && `${fulfilled}%`}
          </span>
        </div>
      </div>
      {showDropdown && <div className={styles.opened_branch}>
        {Object.keys(obj).map(key => {
          const pathArray = path.split('.');
          const title = key === languages.default ? pathArray[pathArray.length - 1] : key;
          const folderPath = key === languages.default ? path : path + '.' + key;
          return (key === 'tip' || (languages.list.includes(key) && key !== languages.default) || !isFolderContainSearchTerm(obj[key], key, searchTerm, folderPath)) ?
            null :
            <Folder key={key} isItFolder={isFolder(obj[key])}
                    title={title} path={folderPath} obj={obj[key]}
                    selectedTranslations={selectedTranslations}
                    selectedFolders={selectedFolders}
                    searchTerm={searchTerm}
                    languages={languages}
                    multiSelect={multiSelect}
                    shiftSelect={shiftSelect}
            />
        })}
      </div>}
    </>
  );
}
